<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="searchView">
			<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
				style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleSearchClick"></el-input>
			<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
			<div class="search">
				<div class="searchTitle">适用宠物:</div>
				<el-select v-model="applyType" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
					<el-option v-for="item in petTypeOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search">
				<div class="searchTitle">库存排序:</div>
				<el-select v-model="stock" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
					<el-option v-for="item in stockOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="btnView">
				<el-button @click="handleResetClick">重置</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="view">
			<div class="tableView">
				<el-table :data="tableData">
					<el-table-column prop="barCode" label="条形码">
					</el-table-column>
					<el-table-column prop="title" label="名称">
					</el-table-column>
					<el-table-column prop="applyType.name" label="适用宠物">
					</el-table-column>
					<el-table-column label="分类">
						<template slot-scope="scope">
							{{scope.row.firstCategoryInfo.name}}/{{scope.row.secondCategoryInfo.name}}
						</template>
					</el-table-column>
					<el-table-column prop="model" label="规格">
					</el-table-column>
					<el-table-column prop="currentCount" label="当前库存">
					</el-table-column>
					<el-table-column prop="minStock" label="最小库存">
					</el-table-column>
				</el-table>
			</div>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
	</div>
</template>

<script>
	import page from "../../../components/page.vue"
	export default {
		components:{
			page
		},
		data() {
			return {
				page:{   //分页
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],//表格
				keyword:'',  //搜索
				applyType: '', //宠物类型
				petTypeOptions: [{label:'全部',value:''},{label:'通用',value:'common'},{label:'猫猫',value:'cat'},{label:'狗狗',value:'dog'},{label:'其他',value:'yichong'}],
				stock: 3,
				stockOptions: [{label:'库存低到高',value:3},{label:'库存高到低',value:4}],
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//筛选
			handleSearchClick(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			//切换分页
			changePage(num){
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//表格
			getTableData(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
					keyword:this.keyword,
					applyType:this.applyType,
					sortIndex:this.stock
				}
				this.$http.post("/product/goods/stock/alert/list", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//重置
			handleResetClick(){
				this.keyword = '';
				this.applyType = '';
				this.stock = 0;
				this.getTableData()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;

		.searchView {
			height: 80px;
			border-radius: 20px;
			margin-bottom: 16px;
			background: #fff;
			display: flex;
			align-items: center;
			padding: 0 20px;

			.search {
				display: flex;
				align-items: center;
				margin-right: 32px;

				.searchTitle {
					font-size: 14px;
					color: $fontColor;
					margin-right: 4px;
				}
			}

			.btnView {
				flex: 1;
				text-align: right;
			}
		}
		.view{
			border-radius: 20px;
			overflow: hidden;
			flex: 1;
			background: #fff;
			display: flex;
			flex-direction: column;
			.tableView{
				flex: 1;
				overflow-y: scroll;
			}
			.tableView::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
